import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import Autocomplete, {
  AutocompleteCloseReason,
  autocompleteClasses,
} from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import InputBase from '@mui/material/InputBase';
import Popper from '@mui/material/Popper';
import { styled } from '@mui/material/styles';
import React, { useCallback, useMemo, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import useAbility from '../../hooks/useAbility';
import {
  getCompaniesList,
  getSelectedCompanyId,
} from '../../redux/companies/selectors';
import { changeSelectedCompany } from '../../redux/companies/thunks';
import { CompanyShort } from '@mgk-eld/utils';
import { CustomDialog } from '@mgk-eld/core';
import { useLocation, useNavigate } from 'react-router-dom';
import { Typography } from '@mui/material';

interface PopperComponentProps {
  anchorEl?: any;
  disablePortal?: boolean;
  open: boolean;
}

const StyledAutocompletePopper = styled('div')(({ theme }) => ({
  [`& .${autocompleteClasses.paper}`]: {
    boxShadow: 'none',
    margin: 0,
    color: 'inherit',
    fontSize: 13,
  },
  [`& .${autocompleteClasses.listbox}`]: {
    backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#1c2128',
    padding: 0,
    [`& .${autocompleteClasses.option}`]: {
      minHeight: 'auto',
      alignItems: 'flex-start',
      padding: 8,
      '&[aria-selected="true"]': {
        backgroundColor: 'transparent',
      },
      [`&.${autocompleteClasses.focused}, &.${autocompleteClasses.focused}[aria-selected="true"]`]:
        {
          backgroundColor: theme.palette.action.hover,
        },
    },
  },
  [`&.${autocompleteClasses.popperDisablePortal}`]: {
    position: 'relative',
  },
}));

function PopperComponent(props: PopperComponentProps) {
  const { disablePortal, anchorEl, open, ...other } = props;
  return <StyledAutocompletePopper {...other} />;
}

export const StyledPopper = styled(Popper)(({ theme }) => ({
  boxShadow: '0px 8px 16px rgba(67, 66, 66, 0.15)',
  borderRadius: '0px 0px 8px 8px',
  width: 284,
  height: 252,
  zIndex: theme.zIndex.modal,
  fontSize: 14,
  backgroundColor: '#fff',
}));

const StyledInput = styled(InputBase)(({ theme }) => ({
  padding: '16px 12px 8px 12px',
  width: '100%',
  '& input': {
    borderRadius: 8,
    backgroundColor: '#F8F8F8',
    padding: '10px 12px',
    border: `1px solid #E5EAED`,
    fontSize: '14px',
  },
}));

const Button = styled(ButtonBase)({
  fontSize: '14px',
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  '& span': {
    width: '100%',
  },
  '& svg': {
    width: 16,
    height: 16,
  },
});

export default function NavbarSelect() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const selectedCompany = useAppSelector(getSelectedCompanyId);
  const companiesList = useAppSelector(getCompaniesList);
  const ability = useAbility();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [dialogOpen, setDialog] = useState<boolean>(false);
  const [searchValue, setSearchValue] = React.useState<CompanyShort | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = useCallback(() => {
    if (anchorEl) {
      anchorEl.focus();
    }
    setAnchorEl(null);
    setSearchValue(null);
  }, [anchorEl]);

  const handleChange = useCallback(
    (
      event: React.SyntheticEvent<Element, Event>,
      newValue: CompanyShort | null
    ) => {
      if (
        event.type === 'keydown' &&
        (event as React.KeyboardEvent).key === 'Backspace'
      ) {
        return;
      }
      const selectedCompanyItem = companiesList.find(
        (c) => c.id === newValue?.id
      );
      setDialog(selectedCompanyItem?.subscription_status !== 'active');
      dispatch(
        changeSelectedCompany(
          !newValue && selectedCompany ? selectedCompany : Number(newValue?.id)
        )
      );
      if (location.pathname === '/logs/details') navigate('/logs');
      if (location.pathname === '/bulk') window.location.reload();
      setSearchValue(newValue);
      handleClose();
    },
    [
      companiesList,
      dispatch,
      handleClose,
      location.pathname,
      navigate,
      selectedCompany,
    ]
  );

  const open = Boolean(anchorEl);
  const id = open ? 'company-label' : undefined;

  const selectedCompanyName = useMemo(
    () => companiesList.find(({ id }) => id === selectedCompany),
    [companiesList, selectedCompany]
  );

  return (
    <React.Fragment>
      {dialogOpen && (
        <CustomDialog
          dialogBody={
            <Typography pl={3} variant="h2">
              This company's status is{' '}
              <span style={{ color: 'red' }}>INACTIVE</span>. Please contact to
              manager to comfirm if support is required.
            </Typography>
          }
          actionText={'OK'}
          actionColor="info"
          open={dialogOpen}
          setOpen={setDialog}
          withCancelBtn={false}
        />
      )}
      <Box
        sx={{
          marginTop: 0.5,
          pl: 2,
          minWidth: 100,
          maxWidth: 250,
          fontSize: 14,
          fontWeight: 500,
        }}
      >
        <Button
          disableRipple
          aria-describedby={id}
          disabled={ability.cannot('click', 'CompanySwitch')}
          onClick={handleClick}
        >
          {selectedCompanyName?.name || ''}
          {anchorEl ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        </Button>
      </Box>
      <StyledPopper
        id={id}
        open={open}
        anchorEl={anchorEl}
        placement="bottom-start"
      >
        <ClickAwayListener onClickAway={handleClose}>
          <div>
            <Autocomplete
              open
              onClose={(
                event: React.ChangeEvent<{}>,
                reason: AutocompleteCloseReason
              ) => {
                if (reason === 'escape') {
                  handleClose();
                }
              }}
              value={searchValue}
              onChange={handleChange}
              disableCloseOnSelect={false}
              PopperComponent={PopperComponent}
              renderTags={() => null}
              noOptionsText="No options"
              renderOption={(props, option) => (
                <li
                  {...props}
                  style={{
                    backgroundColor:
                      option?.id === selectedCompany ? '#E6EFF7' : '',
                  }}
                >
                  <Box
                    sx={{
                      padding: '0 8px',
                      flexGrow: 1,
                      '& span': {
                        color: '#586069',
                      },
                    }}
                  >
                    {option?.name}
                  </Box>
                </li>
              )}
              options={companiesList.filter((c) => !c.deleted)}
              getOptionLabel={(option) => {
                return option.name || '';
              }}
              renderInput={(params) => (
                <StyledInput
                  ref={params.InputProps.ref}
                  inputProps={params.inputProps}
                  autoFocus
                  placeholder="Search"
                />
              )}
            />
          </div>
        </ClickAwayListener>
      </StyledPopper>
    </React.Fragment>
  );
}
